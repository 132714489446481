import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgProfileIanTetley from "../images/profile-ian-tetley.jpg"
import imgProfileDanielMilnes from "../images/profile-daniel-milnes.jpg"
import imgDriver from "../images/shuttleid-tablet-with-driver.jpg"
import "./index.css";
import "./case-study.css";
import Nav from "../components/nav"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import Footer from "../components/footer"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Case study: How Tetley's Coaches stopped £10,000 of ticket fraud and cut 240 hours of admin" />
        <Nav activeTab="case-studies" />
        <div>
            <div>

              <div className="case-study">

                <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                  <h1 className="blog-headline">How we helped Tetley's Coaches stop £10,000 of ticket fraud and cut 240 hours of admin</h1>
                </div>

                <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                  <h1 className="blog-headline">How we helped Tetley's Coaches stop £10,000 of ticket fraud and cut 240 hours of admin</h1>
                </div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <p>Tetley's Coaches believed in automation but suffered by not having the right payment and ticketing solution for their home to school services.</p>
                    <p>Their mobile ticketing system caused severe administration headaches, contributed to £10,000 worth of ticket fraud and led to real concern over when and if ticket revenue would be received. After evaluating their options, they chose a new system for managing payments and ticketing, ShuttleID.</p>
                  </div>
                  <div className='article-inner'>
                    <div className='case-study__metrics'>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>£10,000</span>
                        ticket fraud eliminated
                      </div>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>240 hrs</span>
                        admin saved
                      </div>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>85%</span>
                        faster payouts
                      </div>
                    </div>
                    <div className='case-study__about'>
                      <h3>About Tetley's Coaches</h3>
                      <p><a href="https://www.tetleyscoaches.co.uk/">Tetley's Coaches</a> are an established transport operator based in Leeds, serving the West Yorkshire area for over 70 years. Their work is varied and includes operating private home to school runs; ferrying over 500 pupils daily between homes in Leeds and schools in Harrogate.</p>
                    </div>


                    <h2>Inflexible system drowning staff in admin and ticket fraud</h2>
                    <p>Daniel Milnes, Operations Manager of Tetley's Coaches, was overwhelmed with the extra work their system was creating. Despite saving admin on the physical taking of payment and issuing of passes (all handled online by their system), the flaws of the system were taking valuable time away from the overall day to day business and even putting risk to jobs. Their mobile ticketing was not geared towards the complex relationship of school children (passengers), parents (purchasers) and schools (information stakeholder).</p>
                    <p>Milnes would be many weeks into a school term and still discovering pupils boarding the bus without a valid pass (shared/forged, expired or even none at all). His job would be consumed by chasing parents to buy tickets, which was made near impossible without the data of who was boarding the buses and further complicated by lack of available contact information available, amplifying the issue back onto the schools.</p>
                    <p>With an unfriendly interface, confused parents would often put their own names on bus passes causing a headache for ticket inspection and payment reconciliation. Prohibitively expensive scanning equipment meant Milnes was involved in a never ending game of "cat and mouse", using a single handheld scanner to inspect across eight services. Drivers were left with the difficult job of inspecting passes with the naked eye that ultimately could only be captured by technology.</p>

                    <div className='case-study__profile-quote'>
                      <div className='case-study__profile-quote__img'>
                        <img src={imgProfileDanielMilnes} alt="Daniel Milnes" />
                      </div>
                      <div className='case-study__profile-quote__quote'>
                        <p>"The scale of people not renewing and paying and just trying to board without valid passes, it put someone's job on the line there because one of the services was losing money."</p>
                        <p>"We don't want to turn kids away but at the same time, we want to have a valid pass and without being able to collect names and details on customers who are getting on, which our system wasn't doing, it's hard to pinpoint who we need to chase."</p>
                        <p className='case-study__profile-quote__author'>Daniel Milnes, Operations Manager<br />Tetley's Coaches</p>
                      </div>
                    </div>


                    <h2>Automate everything</h2>
                    <p>Tetley's Coaches considered two main options: Revert back to a labour intensive system of manual payment/data handling with physical photo id card issuing, or try a brand new system, ShuttleID, which automated everything.</p>
                    <p>Milnes was keen to go all in on automation: "When you've got 8 services with 70 people on there, you've then got to collect data for customers, take payment, it's just a massive task. And to have to do it the old way, in issuing cards, you'd have to take on a full time member of staff to administrate it all. There's a cost involved there isn't there? It's a very busy task for a short period of time, for the 3-4 week period running up to a new term, and then there's nothing to do. So it's not really going to pay to set someone on to do it because they're gonna have loads of work for 3 weeks and then nothing for 3 months. So the best way to do it is to automate it in a system like ShuttleID."</p>
                    <div className='case-study__profile-quote'>
                      <div className='case-study__profile-quote__img'>
                        <img src={imgProfileIanTetley} alt="Ian Tetley" />
                      </div>
                      <div className='case-study__profile-quote__quote'>
                        <p>"Then we're faced with the administrative burden of getting that data, producing the passes, posting them or handing them out, dealing with lost passes and so on. And all that is generally contracted into a fairly tight period, it would be July/August when we're quite busy. Even with producing our own passes, we realised that was potentially still open to fraud."</p>
                        <p className='case-study__profile-quote__author'>Ian Tetley, Managing Director<br />Tetley's Coaches</p>
                      </div>
                    </div>
                    <p>ShuttleID worked closely with Tetley's Coaches to develop a platform tailored to their concerns and experience. The solution provided:</p>
                    <ul>
                      <li>Automatic payment handling</li>
                      <li>Self service ticket issuing (mobile or printed)</li>
                      <li>Ticket scanning on every service to police ticket fraud</li>
                      <li>Operational data (sales reporting, scan logs, customer and passenger information)</li>
                    </ul>
                    <p>Using cloud technology and cheaper consumer grade hardware meant the platform was not only cheaper to run, but more importantly it meant complete automation was attainable.</p>

                    <h2>"100% turnaround"</h2>
                    <p>The solution has proved not only a hit with the leadership team at Tetley's Coaches, but drivers, parents and passengers have all taken to it since full roll out.</p>
                    <p>The result is ticket fraud attempts eliminated within the first week, which cost the operator roughly £10,000 the previous year, an estimated 240 hours of admin removed from the operations team and payouts received quicker and reliably into their bank account.</p>
                    <p>Parents are reported to be happier with a system that's quicker and easier to use, drivers are now more active in the policing of ticket fraud with the scanner making their jobs easier, and kids being kids meant passengers took to the technology straight away.</p>
                    <p><img src={imgDriver} alt="Driver using ShuttleID" /></p>
                    <p>"My only concern with the drivers were if they felt scanning was too difficult and time consuming, that they wouldn’t bother. I'd thought there would be more resistance, and there hasn't been. And that has surprised me. There were also concerns from within the operations team that an automated pass system may be too difficult to implement, with an initial preference for the old way of doing things with the cards. However, everyone is now in agreement that after the first couple of months ShuttleID has been a big success for Tetley's. The drivers are reporting that the scanners are picking up scans quickly and the operations team can monitor the data collected easily with the user-friendly operations portal." said Milnes.</p>
                    <p>Milnes was relieved with the pressure it took away: "It's just 100% turnaround, it really is. Last year it took up so much of my time, I think I spent the first two weeks on the road of the school term, so like 6 hours a day for 2 weeks chasing passes and I still didn't get to the root of it. This year, I think within the first week, we more or less had cleared ticket sales and I didn't really need to chase anybody."</p>
                    <div className='case-study__quote'>
                      <p>"There's less operator involvement in the actual process. It just goes on in the background. We've got full observation, transparency, we can see the whole thing, that we know the job's being done."</p>
                      <p>"The fraud has disappeared. I've also got the comfort of knowing that the revenue we do generate is in our bank in fairly quick time."</p>
                      <p className='case-study__quote-author'>&mdash; Ian Tetley, Managing Director, Tetley's Coaches</p>
                    </div>
                    <p>Now with little time required to spend on administering home to school services, Milnes is able to look at other ways of improving the business with time he didn't have previously: "A lot of operators are running lean in the office. Always got our backs against the wall but yeh, where I can save time, I will try to and a system like this, it's been fantastic."</p>
                    <p>"I wouldn't have had time to do a lot of the things I've done this year, you know, looking to change the website, setting up a customer portal, that's taken a lot of time. If I hadn't had ShuttleID, I'm 100% sure that wouldn't have happened, because I would have been on the road for the first two weeks of this year. And it would have all been put to one side, and probably forgotten about. So there's been lots of other things I've been able to look at away from this, which has been great."</p>

                  </div>


                </div>

              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
